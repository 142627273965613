:root {
	--primary-btn: #05233D;
	--box-border-color: #9d9d9d;
	--label-color: #4d4d4d;
	--subLabel-color: #717375;
	--black-color: #000000;
	--white-color: #ffffff;
	--card-bg-color: #f7f7f7;
	--card-divider-color: #e3e3e3;
	--table-header-color: #eef3fb;
	--table-title-color: #292929;
	--table-group-color: #c5dbf5;
	--table-group-text-color: #0066a7;
	--icon-color: #084887;
	--footer-bg-color: #05233D;
	--header-bg: #05233D;
	--footer-bg: #717375;
	--link-color: #2572e7;
	--tooltip-bg-color: #c4c4c4;
	--status-pending-color: #a31e1e;
	--star-icon-color: #e5b50a;
	--disabled-icon: #d1d3d4;
	--reports-icon: #0a41ce;
	--document-icon: #e39908;
	--profile-bg-color: #ededed;
	--tooltip-bg: #868686;
	--sidemenu-bg-color: #f2f2f2;
	--paginate-color: #464646;
	--placeholder-color: #686868;
	--checkbox-border-color: #858585;
	--no-record-color: #848181;
	--loader-color: #e92531;
	--tree-view-color: #e9ecef;
	--deal-detail-header: #4e5763;
	--deal-party: #4E5767;
	--view-all-color: #0f6fdc;
	--grid-border: #d9d9d9;
	--user-bg-color: #e2ebff;

	--status-text-color: #0a41ce;
	--date-color: #14b8dc;

	--card-title: #084887;
	--card-subtitle: #717375;
	--card-info: #007cb0;
	--card-text: #242121;

	--toast-success-color: #60a409;
	--toast-warn-color: #f4b000;
	--toast-bg-color: #999999;
	--initiated-color: #f45800;
	--inProgress-color: #ff8e15;
	--overview-color: #9700f4;
	--vesting-color: #545FE8;
	--paying-color: #0c73ad;
	--account-color: #4e5763;
	--view-color: #0f6fdc;
	--card-title-color: #00a79d;
	--document-color: #242121;
	--solicitation-progress: #f1cc83;
	--solicitation-presented: #8bb909;
	--solicitation-presented-light: #c5dc84;

	--input-focus-color: #40a9ff;
	--pdf-bg: #dedede;
	--error-color: #f44336;
	--hover-color: #d3d3d3;

	--not-solicited-color: #DC6870;
	--not-solicited-light-color: #F9E6E7;
	--solicited-color: #F27E3D;
	--solicited-light-color: #F6CDBF;
	--in-progress-color: #FAA53D;
	--in-progress-light-color: #FFF4E5;
	--present-pending-color: #F1CC84;
	--present-pending-light-color: #FCF5E6;
	--presented-color: #78BF39;
	--presented-light-color: #EBF7DA;
	--paid-color: #7FB778;
	--paid-light-color: #EFFCED;

	--warning-bg-yellow-color: #fcf5e6;
	--maintenane-button-color: #c40311;

	--card-one-deal-library: #b718ef;
	--card-two-deal-library: #0fb5bf;
	--card-three-deal-library: #d30399;

	--progress-bar: #0C66E4;
	--disbursing-card: #CC1D92;
	--others-card: #13C2C2;

	--active: #367A2D;
	--in-active: #919897;
	--address-info: #55605E;
	--mat-divider-color: #C9CECD;
	--disable-toggle-color: #CECECE;
	--positive-toggle-color: #60AF09;
	--tooltip-background-color: #626262;

	--list-bg: #F1F1F1;
	--list-text-color: #222B29;
	--text-color: #7e7e7e;
	--expand-row-color: #D1D3D4;
	--header-border: #90C6E8;
	--sidemenu-active: #303d3b;
	--completed-step: #e82632;
	--loader-backdrop: #6c757d9e;
	--notify-icon: #005288;
	--child-table-bg: #fbfcff;
}
